import { css } from '@emotion/react'
import { type ReactNode } from 'react'
import { spacing } from '../../foundation/spacing-tokens.js'
import { useDownloadFile } from '../../hooks/use-download-file.js'
import { secondaryColor } from '../../theme.js'
import { getFileDisplayName } from '../../util.js'
import { Avatar } from '../atoms/avatar/avatar.js'
import type { CompoundIconName } from '../atoms/index.js'
import { Button, type ButtonProps } from '../button/button.js'
import { Spinner } from '../spinner/index.js'

export type DownloadButtonProps = ButtonProps & {
  displayName?: string
  icon?: CompoundIconName
  iconColor?: string
  children?: ReactNode
  downloadInProgress?: boolean
}

export function DownloadButton(props: DownloadButtonProps): JSX.Element {
  const { displayName, icon, iconColor, children, downloadInProgress, onClick, ...passedProps } = props

  const avatar = downloadInProgress ? (
    <Spinner size="30" strokeColor={iconColor ?? secondaryColor} />
  ) : (
    <Avatar icon={icon ?? 'file-lines-regular'} color={iconColor ?? secondaryColor} />
  )

  return (
    <Button
      {...passedProps}
      onClick={onClick}
      avatar={avatar}
      avatarPosition="left"
      css={css`
        padding-top: ${spacing[30]};
        padding-bottom: ${spacing[30]};
      `}
    >
      {children}
    </Button>
  )
}

export type DownloadButtonWithHrefProps = Omit<ButtonProps, 'onClick' | 'href'> & {
  fileName: string
  displayName?: string
  href: string
  icon?: CompoundIconName
  iconColor?: string
  children?: ReactNode
}

export function DownloadButtonWithHref(props: DownloadButtonWithHrefProps): JSX.Element {
  const { fileName, displayName, href, children, ...passedProps } = props

  const { downloadFile, downloadStatus } = useDownloadFile(href, fileName)

  const onClick = !props.disabled && downloadStatus !== 'downloading' && href ? downloadFile : undefined

  const effectiveDisplayName = displayName || getFileDisplayName(fileName)

  return (
    <DownloadButton onClick={onClick} downloadInProgress={downloadStatus === 'downloading'} {...passedProps}>
      {children ?? effectiveDisplayName}
    </DownloadButton>
  )
}
